import { render, staticRenderFns } from "./DialogIncommingCall.vue?vue&type=template&id=2cfd636c&scoped=true&"
import script from "./DialogIncommingCall.vue?vue&type=script&lang=ts&"
export * from "./DialogIncommingCall.vue?vue&type=script&lang=ts&"
import style0 from "./DialogIncommingCall.vue?vue&type=style&index=0&id=2cfd636c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cfd636c",
  null
  
)

/* custom blocks */
import block0 from "./DialogIncommingCall.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDivider,VExpandTransition,VIcon,VLayout,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VProgressCircular,VScaleTransition,VSnackbar,VTextField})
