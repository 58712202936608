



















/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "btn-on-off-microphone",
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      status: false,
    };
  },
  methods: {
    setStatus() {
      this.$emit("input", this.status || false);
    },
    externalStatus() {
      this.status = !this.status;

      this.$emit("input", this.status);
    },
  },
});
