/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

// Mock data
// const personList = (() => {
//   const result = [];

//   for (let i = 0; i < 15; i++) {
//     result.push({
//       name: "Person name",
//       avatar: `https://randomuser.me/api/portraits/${
//         fastRandom(15) % 2 ? "women" : "men"
//       }/${i}.jpg`,
//       content: "+17863042059",
//       messages: fastRandom(32),
//       time: `${fastRandom(12)}:${fastRandom(59)} ${
//         fastRandom(15) % 2 ? "am" : "pm"
//       }`,
//       online: fastRandom(15) % 2,
//     });
//   }

//   return result;
// })();

const crmSMSModule = {
  namespaced: true,
  state: (): any => {
    return {
      loading: false,
      uploadImgLoading: false,
      // chatContacts: personList,
      messages: [],
      smsContact: [],
      newSms: false,
      messagesFilters: [],
      messagesPatient: [],
      contacts: [],
      contactsUnrelated: [],
      contactSelect: null,
      containunread: false,
      unreads: [],
      numberSelect: null,
      loadingbtw: null,
    };
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default crmSMSModule;
