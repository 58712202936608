/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { Device } from "@/models/Device";
import { Lead } from "@/models/Lead";
import findIndex from "lodash/findIndex";

export function mutSetDevices(state: any, devices: Device[]): void {
  state.devices = devices;
}
export function mutSetLeads(state: any, leads: any[]): void {
  state.leads = leads;
}
export function mutSetHeaders(state: any, headersLead: any[]): void {
  state.headersLead = headersLead;
}
export function mutSetTotalLeads(state: any, totalleads: number): void {
  state.totalleads = totalleads;
}
export function mutSetTotalLlogs(state: any, totallogs: number): void {
  state.totallogs = totallogs;
}
export function mutSetTotalClockdays(state: any, totalclockdays: number): void {
  state.totalclockdays = totalclockdays;
}
export function mutSetTotalOvertimes(state: any, totalovertimes: number): void {
  state.totalovertimes = totalovertimes;
}
export function mutSetTotalPresences(state: any, totalpresences: number): void {
  state.totalpresences = totalpresences;
}
export function mutPhoneNumbers(state: any, phoneNumbers: []): void {
  state.phoneNumbers = phoneNumbers;
}
export function mutPhonesFree(state: any, phoneNumbers: []): void {
  state.phonesFree = phoneNumbers;
}
export function mutSetDeviceSelect(state: any, device: Device): void {
  state.deviceSelect = device;
}
export function mutSetLoading(state: any, loading: boolean): void {
  state.loadingDevice = loading;
}
export function mutSetLoad(state: any, loading: boolean): void {
  state.loading = loading;
}
export function mutSetPayments(state: any, payments: any): void {
  state.payments = payments;
}
export function mutSetFirstPayments(state: any, payments: any): void {
  state.firstpayments = payments;
}

export function mutSetQuotes(state: any, quotes: []): void {
  state.quotes = quotes;
}

export function mutClockin(state: any, clocks: []): void {
  state.clockins = clocks;
}
export function mutClockDays(state: any, clockdays: []): void {
  state.clockdays = clockdays;
}
export function mutOvertimes(state: any, overtimes: []): void {
  state.overtimes = overtimes;
}
export function mutPrecences(state: any, presences: []): void {
  state.presences = presences;
}
export function mutleadSelect(state: any, leadSelect: Lead): void {
  state.leadSelect = leadSelect;
}
export function mutsalesProducts(state: any, salesP: []): void {
  state.salesProducts = salesP;
}
export function mutamountSelect(state: any, amus: any): void {
  state.amountSelet = amus;
}
export function mutImagenSrc(state: any, src: string): void {
  state.imageSrc = src;
}
export function mutTotalxemployee(state: any, totalxemployee: number): void {
  state.totalxemployee = totalxemployee;
}
export function mutTotalFirst(state: any, first: number): void {
  state.totalfirst = first;
}
export function mutSystemLogs(state: any, value: []): void {
  state.systemLogs = value;
}
