import { Device } from "@/models/Device";

export function getDevicesAvailable(state: any) {
  return (state.devices as Device[]).filter((device: Device) => {
    return device.link == null;
  });
}
export function headersLead(state: any) {
  return state.headersLead;
}
export function headersLeadShow(state: any) {
  return state.headersLead.filter((h: any) => h.show != false);
}
