<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <v-menu rounded offset-y :close-on-content-click="false" min-width="350">
        <template v-slot:activator="{ on }">
          <v-badge class="rounded-circle" color="red" :content="countNoRead >= 100 ? '+99' : countNoRead" offset-x="20"
            offset-y="15" :value="countNoRead != 0" bordered top>
            <v-btn v-on="on" :loading="loading" icon><v-icon>mdi-bell</v-icon></v-btn>
          </v-badge>
        </template>

        <v-sheet rounded class="d-flex flex-column pa-3">
          <div class="d-flex">
            <span class="headline font-weight-bold">Notifications</span>
            <v-spacer></v-spacer>
            <template v-if="notifyshow.length != 0">
              <v-menu bottom close-on-click min-width="160">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                </template>

                <v-list>
                  <v-list-item @click="checkAll">
                    <v-list-item-title>{{
                      "Check all as read"
                    }}</v-list-item-title>
                  </v-list-item>
                  <!--  <v-list-item @click="unreadAll">
                  <v-list-item-title>{{
                    "Check all as unread"
                  }}</v-list-item-title>
                </v-list-item> -->
                </v-list>
              </v-menu>
            </template>
          </div>
          <v-list>
            <v-list-item-group v-model="selectedItem" class="listnotify" color="primary">
              <v-list-item v-for="item in notifyshow" :key="item.uuid">
                <v-list-item-content>
                  <v-list-item-title class="d-flex">
                    <div :class="!item.readed ? 'font-weight-bold' : 'text--secondary'
                      ">
                      {{ item.content }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="success" v-on="on" x-small icon
                          @click="readNotification(item.uuid)"><v-icon>mdi-check</v-icon></v-btn>
                      </template>
                      <span>Read Notification</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="error" v-on="on" x-small icon
                          @click="toDelteNotify(item.uuid)"><v-icon>mdi-delete</v-icon></v-btn>
                      </template>
                      <span>Delete Notification</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right blue--text">{{
                    getTime(item.createdAt)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <template v-if="notifyshow.length == 0">
              <v-list-item>
                <v-list-item-title class="text-center">You have no unread notifications!</v-list-item-title>
              </v-list-item>
            </template>

            <v-divider></v-divider>
            <v-list-item @click="viewAll">
              <v-list-item-title class="text-center">View All</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-sheet>
        <v-dialog v-model="deleteDialog" persistent :overlay="false" max-width="400px" transition="dialog-transition">
          <v-card>
            <v-card-title class="error white--text">
              Delete Notification
            </v-card-title>
            <v-card-text class="mt-4 text-center">
              <h4>Are you sure you want delete this notification?</h4>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn @click="cancelDelete" :disabled="loading" text>Cancel</v-btn>
              <v-btn color="error" :loading="loading" :disabled="loading" text @click="confirmDelte">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-menu>
    </template>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { apiBaseUrl } from "@/enviorment";

import axios, { AxiosError } from "axios";

import { mapActions, mapMutations, mapState } from "vuex";
import { utilMixin } from "@/utils/mixins";
import longTime from "@/utils/longTime";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "../Notification";

export default {
  name: "btn-notifications",
  mixins: [utilMixin],
  data() {
    return {
      loading: false,
      selectedItem: null,
      deleteDialog: false,
      uuidNotify: null,
    };
  },
  methods: {
    ...mapActions(["actGetNotifications"]),
    getTime(date) {
      return longTime(date);
    },

    readNotification(value) {
      this.loading = true;
      getAPI
        .post("/notify/read/" + value)
        .then(() => {
          this.loading = false;
          this.actGetNotifications();
        })
        .catch((err) => {
          this.loading = false;
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    readNotifications(value) {
      getAPI
        .post("/notify/read/" + value)
        .then(() => {
          //
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    /* unReadNotifications(value) {
      getAPI
        .post("/notify/update/" + value, { readed: false })
        .then(() => {
          //
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    }, */

    checkAll() {
      this.notifications.forEach(async (element) => {
        if (!element.readed) {
          await this.readNotifications(element.uuid);
        }
      });
      this.actGetNotifications();
    },
    /*  unreadAll() {
      this.notifications.forEach(async (element) => {
        if (element.readed) {
          await this.unReadNotifications(element.uuid);
        }
      });
      this.actGetNotifications();
    }, */

    toDelteNotify(value) {
      this.deleteDialog = true;
      this.uuidNotify = value;
    },
    cancelDelete() {
      this.deleteDialog = false;
      this.uuidNotify = null;
    },
    confirmDelte() {
      this.loading = true;

      getAPI
        .delete("/notify/delete/" + this.uuidNotify)
        .then(() => {
          this.loading = false;
          this.cancelDelete();
          notifyInfo("Notification has been delete");
          this.actGetNotifications();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    viewAll() {
      this.$router.push({
        name: "All Notifications",
      });
    },
  },
  watch: {
    activenotify(val) {
      if (val) {
        this.actGetNotifications();
      }
    },
  },

  computed: {
    ...mapState(["notifications", "activenotify"]),
    countNoRead() {
      if (
        this.notifications.length == null ||
        this.notifications == undefined
      ) {
        return 0;
      }
      const x = this.notifications.filter((n) => n.readed == false);
      return x.length;
    },
    notifyshow() {
      if (
        this.notifications.length == null ||
        this.notifications == undefined
      ) {
        return [];
      }
      let x = this.notifications.filter((n) => n.readed == false);
      let res = []
      if (x.length>100) {
        for (let index = 0; index < 100; index++) { 
          const element=x[index]; 
          res.push(element)
        }
      }else{
        res=x
      }
      
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
.listnotify {
  max-height: 600px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.notifysheet {
  border-radius: 125px !important;
}
</style>
