import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

const crmConfigurationsModule = {
  namespaced: true,
  state: (): any => {
    return {
      devices: [],
      deviceSelect: null,
      loading: false,
      leads: [],
      totalleads: 0,
      payments: [],
      firstpayments: [],
      totalfirst: 0,
      clockins: [],
      clockdays: [],
      phoneNumbers: [],
      phonesFree: [],
      imageSrc: "",
      totalclockdays: 0,
      overtimes: [],
      totalovertimes: 0,
      presences: [],
      totalxemployee: 0,
      totalpresences: 0,
      quotes: [],
      salesProducts: [],

      systemLogs: [],
      totallogs: 0,
      leadSelect: null,
      amountSelet: null,
      // Loading status for update, list and remove
      loadingDevice: false,
      headersLead: [
        {
          text: "Creation Date",
          align: "start",
          sortable: false,
          value: "createAt",
          show: true,
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          show: true,
        },
        // { text: "Email", value: "email", sortable: false, show: true },
        { text: "Phone", value: "phone", sortable: false, show: true },
        { text: "Source", value: "sourceNom", sortable: false, show: true },
        {
          text: "Language",
          value: "preferLanguage",
          sortable: false,
          show: true,
        },
        { text: "Tag", value: "tagNom", sortable: false, show: false },

        { text: "Agent", value: "coordinator", sortable: false, show: true },
        {
          text: "R-Date",
          align: "start",
          width: 110,
          value: "rd",
          sortable: false,
          show: true,
        },
        {
          text: "Members",
          value: "members",
          align: "center",
          sortable: false,
          show: false,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          show: true,
        },
        {
          text: "Quote Date",
          value: "quotedDate",
          align: "center",
          sortable: false,
          show: false,
        },
        {
          text: "Sms",
          value: "smsCount",
          sortable: false,
          align: "center",
          show: true,
        },
        {
          text: "Calls",
          value: "callCount",
          sortable: false,
          align: "center",
          show: true,
        },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
          show: true,
        },
      ],
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default crmConfigurationsModule;
