






/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

export default Vue.extend({
  name: "btn-pause",
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      status: false,
    };
  },
  watch: {
    value(val: boolean): void {
      this.status = val;
    },
  },
  methods: {
    onClick() {
      this.status = !this.status;
      this.$emit("input", this.status);
      this.$emit("click");
    },
  },
});
