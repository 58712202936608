
























/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import BtnPause from "@/components/buttons/BtnPause.vue";
import prettyTime from "@/utils/prettyTime";

export default Vue.extend({
  components: { BtnPause },
  name: "record-call",
  data() {
    return {
      processId: 0,
      pausedRecord: false,
      recording: false,
      recordingTime: 0,
      stopedRecord: false,
    };
  },
  methods: {
    prettyTime,
    startTime(): void {
      (this as any).processId = setInterval(() => {
        this.recordingTime = this.recordingTime + 1;
      }, 1000);
    },
    startRecord(): void {
      this.recording = true;
      this.$emit("record-start", this.recording);
      this.startTime();
    },
    stopRecord(): void {
      this.recording = false;
      this.stopedRecord = true;
      this.$emit("record-stop", this.recording);
      clearInterval(this.processId);
    },
    pauseRecord(): void {
      if (this.pausedRecord) {
        clearInterval(this.processId);
        this.$emit("record-paused", this.pausedRecord);
      } else {
        this.startRecord();
      }
    },
  },
});
