import SendedSMS from "@/models/SendedSMS";
import { Contact } from "@/models/Sms";

export function mutLoading(state: any, loading: boolean): void {
  state.loading = loading;
}
export function mutLoadingImg(state: any, loading: boolean): void {
  state.uploadImgLoading = loading;
}
export function mutnewSms(state: any, smsnew: boolean): void {
  state.newSms = smsnew;
}
export function mutLoadingBtw(state: any, loading: boolean): void {
  state.loadingbtw = loading;
}

export function mutSetMessages(state: any, messages: any): void {
  state.messages = messages;
}
export function mutSetSmsContact(state: any, messages: any): void {
  state.smsContact = messages;
}
export function mutSetUnreads(state: any, unreads: any): void {
  state.unreads = unreads;
}
export function mutSetMessagesFilters(state: any, messages: any): void {
  state.messagesFilters = messages;
}
export function mutSetMessagesPatient(state: any, messages: any[]): void {
  state.messagesPatient = messages;
}
export function mutSetContact(state: any, contact: Contact): void {
  state.contactSelect = contact;
}
export function mutSetContactUnrelated(state: any, contact: Contact): void {
  state.contactsUnrelated = contact;
}
export function mutcontacts(state: any, contacts: Contact[]): void {
  state.contacts = contacts;
}
export function mutContainUnread(state: any, containunread: boolean): void {
  state.containunread = containunread;
}
export function mutnumberSelect(state: any, numberSelect: string): void {
  state.numberSelect = numberSelect;
}
