/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";

/* eslint-disable prettier/prettier */
export const funtionsPhone = {
  methods: {
    copyPhone(value: string) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          notifyInfo(`Phone Number was copied: ${value}`);
        })
        .catch((err) => {
          notifyError("Phone Number could not be copied", `An error occurred`);
        });
    },
    copyPolizeNumber(value: string) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          notifyInfo(`Policy Number was copied: ${value}`);
        })
        .catch((err) => {
          notifyError("Policy Number could not be copied", `An error occurred`);
        });
    },
    makeCallV(item: any) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: item,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },
  },
};
