





































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import BtnCall from "../buttons/BtnCall.vue";
import rules from "@/components/account/rules";
export default Vue.extend({
  name: "dialer",
  components: { BtnCall },
  //inject: ["callCenterProvider"],

  props: {
    value: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      number: "",
      keys: [
        {
          key: "1",
          labels: "",
        },
        {
          key: "2",
          labels: "ABC",
        },
        {
          key: "3",
          labels: "DEF",
        },
        {
          key: "4",
          labels: "GHI",
        },
        {
          key: "5",
          labels: "JKL",
        },
        {
          key: "6",
          labels: "MNO",
        },
        {
          key: "7",
          labels: "PQRS",
        },
        {
          key: "8",
          labels: "TUV",
        },
        {
          key: "9",
          labels: "WXYZ",
        },
        {
          key: "*",
          labels: "",
        },
        {
          key: "0",
          labels: "+",
        },
        {
          key: "#",
          labels: "",
        },
      ],
      disableNumber: false,
      numberValid: false,
      ringing: false,
      deviceReady: false,
      twilioDeviceProvider: null,
      outgoingConnection: null,
      rules,
    };
  },
  mounted() {
    this.number = this.value;
  },
  methods: {
    dial(number: string): void {
      if (this.number.length >= 16) return;

      this.number = this.number + number;
      // To use width v-model
      this.$emit("input", this.number);
    },
    backspace(): void {
      if (this.number.trim() != "") {
        this.number = this.number.slice(0, -1);
      }
    },
    // Solo se permiten números
    onInput(event: any) {
      const allowKeys = "1 2 3 4 5 6 7 8 9 0 + - # *";
      const { key } = event;

      if (allowKeys.includes(key)) {
        this.dial(event.key);
      }
    },
    makeCall() {
      this.number = "+1" + this.number;
      this.$emit("make-call", this.number);
      this.number = "";
    },
  },
});
